import React from 'react'
import LogoBanner from '../../assets/images/logo-banner.png'
import Kid from '../../assets/images/kid-in-hero-baner.png'
import Circle from '../../assets/images/eclipps.png'
import './HeroSection.css'
function HeroSection() {
  return (
    <div>
        <section class="banner_section">
        <div class="container">
            <div class="hero-section-container row">
                    <div class="col-md-5 align-items-center">
                        <div style={{height:'70px'}} class="logo">
                        </div>
                        <div class="content">
                            <h1 class="banner_heading">Get The Best    <span class="yellow"> Children's Book </span>Illustration Services</h1>
                            

                            <img class="brand-logo" src={LogoBanner} alt='brand'/>
                            {/* <div className="brand-images-container">

                            </div> */}
                      
                            <div class="banner_btn">
                                <button type="button" class="btn-close avail" aria-label="Close">avail 50% off now</button>
                                <button type="button" class="btn-close pricing" aria-label="Close">view pricing</button>
                            </div>
                    </div>
                </div>
                {/* <div > */}
                    <div style={{backgroundImage:`url(${Circle})`, backgroundSize:'contain', backgroundRepeat:'no-repeat',backgroundPosition:'bottom'}} class="right-img">
                        <img class="rightside_kids" src={Kid} alt='kids'/>
                    </div>
                {/* </div> */}
            </div>
        </div>
    </section>
    </div>
  )
}

export default HeroSection