import React from "react";
import Cube from "../../assets/images/cube-3.png";
import TestimonialPerson from "../../assets/images/testimonal person.png";
import TestimonialPerson2 from "../../assets/images/testimonial-2.png";
import TestimonialPerson3 from "../../assets/images/testimonial-4.png";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Testimonials.css";
function Testimonials() {
  return (
    <section class="testimoials">
      <div class="container">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8 align-middle">
            <h2 class="testimanh">
              What do Our Clients say <span class="perpel">About Us</span>
            </h2>
            <img class="cube_3" src={Cube} alt="1" />
            <div class="lead-testimonial-cont">
              <Swiper
                cssMode={true}
                pagination={{
                  type: "custom", // Set the pagination type to 'progressbar'
                }}
                mousewheel={true}
                keyboard={true}
                autoplay={{
                  delay: 5500,
                  disableOnInteraction: false,
                }}
                navigation={true}
                modules={[
                  Navigation,
                  Pagination,
                  Mousewheel,
                  Keyboard,
                  Autoplay,
                ]}
                className="mySwiper1"
                style={{ height: "100%" }}
              >
                <SwiperSlide>
                  <div class="lead-tstmnl-slide">
                    <div class="lead-tstmnl-img">
                      <img src={TestimonialPerson3} alt="1" />
                    </div>
                    <div class="lead-tstmnl-text-cont">
                      <h2 class="client_name">OLIVER </h2>
                      <span class="lead-tstmnl-comment">
                        “I wanted to publish my first children's book, and AWA
                        did great work for me I am very happy with their
                        services. ”
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="lead-tstmnl-slide">
                    <div class="lead-tstmnl-img">
                      <img src={TestimonialPerson} alt="1" />
                    </div>
                    <div class="lead-tstmnl-text-cont">
                      <h2 class="client_name">ETHAN </h2>
                      <span class="lead-tstmnl-comment">
                        “I am honestly happy with the way things worked out for
                        me. Their prices were very reasonable for me. Thank u
                        for a good service, ”
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="lead-tstmnl-slide">
                    <div class="lead-tstmnl-img">
                      <img src={TestimonialPerson2} alt="1" />
                    </div>
                    <div class="lead-tstmnl-text-cont">
                      <h2 class="client_name">BENJAMIN</h2>
                      <span class="lead-tstmnl-comment">
                        “I am very content to receive such great service from
                        these people. They are good people, and I am happy with
                        their service. ”
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
               
              </Swiper>

              {/* <div class="lead-tstmnl-slider">
                            <div class="lead-tstmnl-slide">
                                <div class="lead-tstmnl-img">
                                    <img src={TestimonialPerson} alt='1'/>
                                </div>
                                <div class="lead-tstmnl-text-cont">
                                    <h2 class="client_name">Benjiman parker</h2>
                                    <span class="lead-tstmnl-comment">
                                        Sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                    </span>
                                    
                                </div>
                            </div>
                            <div class="lead-tstmnl-slide">
                                <div class="lead-tstmnl-img">
                                    <img src={TestimonialPerson} alt='1'/>
                                </div>
                                <div class="lead-tstmnl-text-cont">
                                    <h2 class="client_name">Benjiman parker</h2>
                                    <span class="lead-tstmnl-comment">
                                        Sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                    </span>
                                    
                                </div>
                            </div>
                            <div class="lead-tstmnl-slide">
                                <div class="lead-tstmnl-img">
                                    <img src={TestimonialPerson} alt='1'/>
                                </div>
                                <div class="lead-tstmnl-text-cont">
                                    <h2 class="client_name">Benjiman parker</h2>
                                    <span class="lead-tstmnl-comment">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                        consequat. Duis aute irure dolor in reprehenderit in voluptate.
                                    </span>
                                    
                                </div>
                            </div>
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
