import React , {useState , useEffect} from 'react'
import Slider1 from '../../assets/images/step-3_01.png'
import Slider2 from '../../assets/images/step-2.png'
import Slider3 from '../../assets/images/step-3_03.png'
import { Navigation, Pagination } from 'swiper/modules';
import "./ProcessSection.css"
import { Swiper, SwiperSlide } from 'swiper/react';
function ProcessSection() {
    const [active , setActive] = useState(0)
    const [spaceBetween, setSpaceBetween] = useState(30); // Default spaceBetween value

    const slider = [
        {
            sliderImg: Slider1,
            text : 'Time Appeal'
        },
        {
            sliderImg: Slider2,
            text : 'Time Appeal'
        },
        {
            sliderImg: Slider3,
            text : 'Time Appeal'
        },
    ]
    const updateSpaceBetween = () => {
        const viewportWidth = window.innerWidth;

        // Define breakpoints for changing spaceBetween values
        if (viewportWidth < 768) {
            setSpaceBetween(120);
        } else {
            setSpaceBetween(30);
        }
    };
    useEffect(() => {
        updateSpaceBetween(); // Initial call
        window.addEventListener('resize', updateSpaceBetween);
        return () => {
            window.removeEventListener('resize', updateSpaceBetween);
        };
    }, []);
  return (
    <section class="step_slider">
    {/* <div class="container"> */}
        <div style={{margin:"0px"}} class="row">
            <div style={{padding:"0px"}} class="col-md-12">
                <h2 class="process" > <span class="yellow">AWA  <br/> </span>PROCESS OF CHILDREN'S BOOK PUBLISHING SERVICES</h2>
                <Swiper 

                            slidesPerView={'auto'}
                            centeredSlides={true}
                            spaceBetween={spaceBetween}
                            onSlideChange={e=>setActive(e.activeIndex)}
                            // initialSlide={1}
                            pagination={{
                                type:'custom'                            
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper2"
                        >
                        {
                            slider.map((slide , index)=>(
                                <SwiperSlide>
                                {/* <div style={{ transform:`scale(${active === index ?"1" : "0.7"})`,transition: "all 0.5s"}}> */}
        
                                {/* <div className='process-slider-img' > */}

                                    <img className='process-slider-img' style={{opacity:`${active===index? '1': "0.3"}`, transition:'all 0.5s'}} src={slide.sliderImg} alt='1' />

                                {/* </div> */}
                                {/* </div> */}
                            </SwiperSlide>

                            ))
                        }
                      
                     
                                    
                 
                    </Swiper>
            </div>
        {/* </div> */}
    </div>
</section>
  )
}

export default ProcessSection