import React, { useState , useRef } from "react";
import toast from "react-hot-toast";
import Kid from '../../assets/images/footer-kid.png'
import { Divider } from "antd";
import { Constant } from "../../constants/constants";
import axios from "axios";

function Footer() {
    const form = useRef();

    const [data ,setData ] = useState({
        name : "",
        email:"",
        message:"",
        phone:""
    })
    const handleInputChange = (e) => {
        const inputValue = e.target.value;  
    
        // Use a regular expression to allow only numeric characters
        const numericValue = inputValue.replace(/[^0-9]/g, "");
    
        setData({
          ...data,
          phone: numericValue
        });
      };
    const handleSubmit = (e)=>{
        e.preventDefault()
      // Validation checks
  if (!data.name.trim()) {
    toast.error("Please enter your Name");
    return;
  }


  if (!data.phone.trim() || !/^\d{11}$/.test(data.phone)) {
    toast.error("Please enter a valid Phone Number");
    return;
  }

  if (!data.email.trim() || !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i.test(data.email)) {
    toast.error("Please enter a valid Email Address");
    return;
  }

  if (!data.message.trim()) {
    toast.error("Please enter a Message");
    return;
  }
  axios.post(Constant.BASE_URL+'register', data).then((response)=>{
      console.log(response);
      
  }).catch(()=>{

  })
  // If all validations pass, you can submit the form data
  console.log(data);
    }
  return (
    <div>
      <footer class="footer_Sec">
        <div class="container no-pad-container">
          <div
            style={{ height: "100%", display: "flex", flex: 1, width: "100%" }}
            class="footer-main-container"
          >
            {/* <div class="col-md-6 "> */}
            <div class="ftr_content">
              <h2>Address:</h2>
              <p>{Constant.Address}</p>
              <h2>Phone:</h2>
              <p>{Constant.PhoneNumber}</p>
              <h2>Email:</h2>
              <p>{Constant.Email}</p>
              {/* </div> */}
            </div>
            {/* <div class="col-md-6"> */}
            <Divider className="divider-footer" style={{ height:'353px',border:'1px solid #fff'}} type="vertical"/>
            <div class="ftr_forms">
              <img className="footer-kid-img" src={Kid} alt="" />
              <h2>
              Reach Us <br/> Online 
              </h2>
   
              <form ref={form} onSubmit={handleSubmit} class="forms">
                <div className="footer-input-flex" style={{display:'flex',justifyContent:'center',alignItems:'center', gap:'5px'}}>

                           <input required onChange={(e)=>setData({...data ,name:e.target.value})} class="nav-item" type="text" placeholder="Name" />
                           {/* <input required onChange={(e)=>setData({...data ,lastName:e.target.value})} class="nav-item" type="text" placeholder="Last Name" /> */}
                           <input
                                required
                                onChange={handleInputChange}
                                value={data.phone}
                                className="nav-item"
                                type="tel"
                                placeholder="Phone Number"
                                />                        
                                </div>
                        <input required onChange={(e)=>setData({...data ,email:e.target.value})} class="nav-item email-field" type="email" placeholder="Email Address" />
                           <textarea placeholder="Write a message" required onChange={(e)=>setData({...data ,message:e.target.value})} class="form-control nav-item" id="exampleFormControlTextarea1" rows="2"></textarea>
                           <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>

                           <button  onClick={handleSubmit} type="submit" class="btn-close pricingperpal-2" aria-label="Close">Submit</button>
                           </div>
                
                </form>
            </div>
          </div>
        </div>
      </footer>
      <section class="lastftr">
        <div class="container">
          <div class="footer-right-container">
              <div class="lastft">
                <p style={{margin:'0px', padding:'0px'}}>2023-all right reserved American Writers Association. </p>
            </div>
              <div class="terms">
                <p>Terms & Conditions | Privacy Policy</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
