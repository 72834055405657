import React from 'react';
import "./Header.css"
import Logo from '../../assets/images/logo.png'
const Header = () => {
  return (
  //  <div style={{backgroundImage:`url(${Bg})`, position:'sticky'}} >
   <div className="header-wrapper" >

    <div className="nav-1">        
      <div className="logo-1">

            <img className='logo-nav' src={Logo} width="140" height="85" alt='1' /><div class="res-show">
                
                <a class="btn phone-number" href="/"> <i class="fa fa-phone fa-heart fa-beat" aria-hidden="true" style={{'--fa-animation-duration': '0.5s'}}></i></a>  
            </div></div>
          
        <div className="header-right">
    <div style={{display:'flex', gap:'20px'}} class="btnNubWrap mob">
                  
                    <div style={{background: '#8d42c1'}} class="hero-btn">
                        <a style={{color:'#fff'}} class="btn phone-number" href="/"> <i class="fa fa-phone fa-heart fa-beat" aria-hidden="true" style={{'--fa-animation-duration': '0.5s'}}>  </i> <span className='display-none'>
                          
                          (877) 251-5759
                          </span> 
                          </a>         
                      </div>
                      <div class="hero-btn live-chat">
                          Start a Live Chat               
                      </div>
                </div>  
          
          </div>

    </div>    

</div>
  //  </div>
  );
};

export default Header;
