import React from "react";
import "./ServiceSection.css";
import Kid from "../../assets/images/kidsnew.png";
import Point1 from "../../assets/images/points_01.png";
import Point2 from "../../assets/images/points_02.png";
import Point3 from "../../assets/images/points_03.png";
import Point4 from "../../assets/images/points_04.png";

function ServiceSection() {
  return (
    <div>
      <section class="services_sections">
        <div style={{ marginRight: "0px" }} class="row">
          <div style={{ position: "absolute", float: "left" }} class="col-md-5">
            <div class="service_image">
              <img class="service_kid" src={Kid} alt="kid" />
            </div>
          </div>
          <div class="container">
            <div style={{ float: "right" }} class="col-md-7">
              <div class="inner_services">
                <h2>
                  AWA Publishing Services for
                  <span class="yellow">Children's Books </span>
                </h2>
                <p class="services_deptn">
                  We at AWA offer extraordinary services to share your story
                  with children that boost their imagination and uplift their
                  interest in reading. Our team of expert illustrators provides
                  extraordinary services that bring liveliness on every page. We
                  add magical ingredients that make your children's book unique.
                  Our stories offer a memorable experience to young readers!
                </p>
                <div class="ul_images">
                  <ul class="servis">
                    <li>
                      <img src={Point1} alt="1" />{" "}
                    </li>
                    <li>
                      <img src={Point2} alt="1" />{" "}
                    </li>
                    <li>
                      <img src={Point3} alt="1" />{" "}
                    </li>
                    <li>
                      <img src={Point4} alt="1" />{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServiceSection;
