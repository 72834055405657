import React from "react";
import CTCImg from "../../assets/images/ctc-2-image.png";

function DiscountSection() {
  return (
    <div>
      <section class="callto_action">
        <div class="container">
          <div
            className="call-action-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "334px",
            }}
          >
            {/* <div class="col-md-8"> */}
            <div class="call_img">
              <img className="img-dicount-section" src={CTCImg} alt="1" />
            </div>
            {/* </div> */}
            {/* <div class="col-md-5"> */}
            <div class="cta_content">
              <h2>ORDER YOUR CHILDREN BOOK</h2>
              <p>And receive awe-struck illustrations for your book. </p>
              <div style={{ width: "100%" }} class="banner_btns">
                <button
                  type="button"
                  class="btn-close availperpal"
                  aria-label="Close"
                >
                  avail 50% off now
                </button>
                <button
                  type="button"
                  class="btn-close pricingperpal"
                  aria-label="Close"
                >
                  view pricing
                </button>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DiscountSection;
