import React, { useState, useEffect } from "react";
import Slider1 from "../../assets/images/slider-1.png";
import Slider2 from "../../assets/images/slider-2.png";
import Slider3 from "../../assets/images/slider-3.png";
import Kid from "../../assets/images/bk-kids.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "./BookPublish.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper/modules";

function BookPublish() {
  const [active, setActive] = useState(0);
  const [spaceBetween, setSpaceBetween] = useState(-150); // Default spaceBetween value

  const slider = [
    {
      sliderImg: Slider1,
      text: "Manuscript Revision",
    },
    {
      sliderImg: Slider2,
      text: "Editing & Formatting ",
    },
    {
      sliderImg: Slider3,
      text: "Publishing        ",
    },
  ];
  const updateSpaceBetween = () => {
    const viewportWidth = window.innerWidth;

    // Define breakpoints for changing spaceBetween values
    if (viewportWidth < 768) {
      setSpaceBetween(-30);
    } else {
      setSpaceBetween(-130);
    }
  };
  useEffect(() => {
    updateSpaceBetween(); // Initial call
    window.addEventListener("resize", updateSpaceBetween);
    return () => {
      window.removeEventListener("resize", updateSpaceBetween);
    };
  }, []);
  return (
    <div>
      {" "}
      <section class="book_publishing-services">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <div class="book_servicesslide">
                <div class="slide_contents">
                  <h2>
                    AWA OFFERS EXCEPTIONAL{" "}
                    <span class="perpel">BOOK PUBLISHING SERVICES</span>
                  </h2>
                  <p class="book_servicsp">
                    AWA offers an exceptional team of writers, illustrators, and
                    publishers collaborating seamlessly for a magical experience
                    for young readers. We breathe life into your visions and
                    craft top-notch designs that captivate and inspire the
                    children's experience.
                  </p>
                </div>
                <div className="slider-wrapper-container">
                  <Swiper
                    slidesPerView={"auto"}
                    centeredSlides={true}
                    // spaceBetween={-30}
                    spaceBetween={spaceBetween}
                    onSlideChange={(e) => setActive(e.activeIndex)}
                    initialSlide={1}
                    pagination={{
                      // clickable: true,
                      type: "custom",
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper5"
                  >
                    {slider.map((slide, index) => (
                      <SwiperSlide>
                        <div
                          style={{
                            transform: `scale(${
                              active === index ? "1" : "0.7"
                            })`,
                            transition: "all 0.5s",
                          }}
                        >
                          {active === index ? (
                            <>
                              <div
                                className="service-slider-img"
                                style={{
                                  backgroundImage: `url(${slide.sliderImg})`,
                                }}
                              >
                                {/* <img src={Slider1} alt='1' /> */}
                              </div>
                              <h3 class="timeless">{slide.text}</h3>
                            </>
                          ) : (
                            <div className="service-slider-img">
                              <img
                                height={100}
                                width={100}
                                src={slide.sliderImg}
                                alt="1"
                              />
                            </div>
                          )}
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="bkservices_rightimg">
                <img
                  style={{ height: "auto", width: "100%" }}
                  src={Kid}
                  alt="1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BookPublish;
